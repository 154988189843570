import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { useAppDispatch, type RootState } from '../grid/reduxStore/Store';
import { reorderSections } from '../grid/reduxStore/editorSlice';

export type SimpleSection = {
  id: string;
  order: number;
  title: string;
};

type UseOrderedSectionsProps = {
  sections: SimpleSection[];
  reorder: (fromIndex: number, toIndex: number) => void;
};

const temporarySectionsSelector = createSelector(
  (state: RootState) => state.gridBlockReducer.sections,
  (sections) => Object.values(sections).sort((a, b) => a.order - b.order)
);

export const useOrderedSections = (): UseOrderedSectionsProps => {
  const dispatch = useAppDispatch();

  // sections will already be in order in redux state,
  // when we will adapt the store to use arrays for sections instead of order property.
  // meanwhile, we have this to get the sections in order.
  const sections = useSelector(temporarySectionsSelector);

  // temporary before we use the reordering algo from backend instead
  const reorder = (fromIndex: number, toIndex: number) => {
    const mutableSections = sections.map((section) => ({ id: section.id, order: section.order }));

    const draggedSection = mutableSections[fromIndex];
    draggedSection.order = toIndex;

    if (toIndex > fromIndex) {
      const sectionThatShiftsUp = mutableSections[toIndex];
      sectionThatShiftsUp.order = fromIndex - 0.5;
    } else if (toIndex < mutableSections.length) {
      const sectionThatShiftsDown = mutableSections[toIndex];
      sectionThatShiftsDown.order = toIndex + 0.5;
    }

    const sectionsByOrder = mutableSections.sort((a, b) => a.order - b.order);

    dispatch(reorderSections(sectionsByOrder));
  };

  return { sections, reorder };
};
